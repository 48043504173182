//  export const baseurl = "https://clnode-pawsome.mobiloitte.io/"; // staging
//  export const socketBaseurl = "https://clnormalsocket-pawsome.mobiloitte.io/"; // staging

// export const baseurl = "http://172.16.1.254:1944/"; // local
// export const socketBaseurl = "http://172.16.1.254:1946/"; // local

export const baseurl = "https://node.pawsome.host/"; // live client
export const socketBaseurl = "https://normalsocket.pawsome.host/"; // live

// export const baseurl = "https://node-pawsome.mobiloitte.io/"; // live client
// export const socketBaseurl = "https://pawsome-normalsocket.mobiloitte.io/"; // live

export const chatApiMainUrl = socketBaseurl.includes(
  "https://normalsocket.pawsome.host/"
);

let base = `${baseurl}api/v1`;
let user = `${base}/user`;
let nft = `${base}/nft`;
let admin = `${base}/admin`;
let ststic = `${base}/static`;
let event = `${base}/event`;
let circuit = `${base}/circuit`;
let practice = `${base}/practice`;
let petstore = `${base}/petstore`;

const Apiconfigs = {
  // admin

  login: `${admin}/login`,
  forgotPassword: `${admin}/forgotPassword`,
  resetPassword: `${admin}/resetPassword/`,
  userList: `${admin}/userList`,
  usernftEventList: `${admin}/usernftEventList`,
  socialLinkList: `${admin}/socialLinkList`,
  socialLink: `${admin}/socialLink`,
  blockUnblockUser: `${admin}/blockUnblockUser `,
  dashboard: `${admin}/dashboard`,
  user: `${admin}/user`,
  userAdmin: `${admin}/user/`,
  listCategory: `${admin}/listCategory`,
  listEventAdmin: `${admin}/listEvent`,
  nftList: `${admin}/nftList`,
  productTransactionList: `${admin}/productTransactionList`,
  stakeDistributionHistoryList: `${admin}/stakeDistributionHistoryList`,

  //PetStore
  createPetItems: `${petstore}/createPetItems`,
  listPetStoreApi: `${petstore}/listPetStoreApi`,
  listPetItems: `${petstore}/listPetItems`,
  deletePetItems: `${petstore}/deletePetItems`,
  viewPetItem: `${petstore}/viewPetItem`,
  editPetItems: `${petstore}/editPetItems`,
  myPurchaseList: `${petstore}/myPurchaseList`,
  powerUps: `${petstore}/powerUps`,
  // listPetStoreApi: `${petstore}/listPetStoreApi`,
  buyPetItem: `${petstore}/buyPetItem`,
  powerDown: `${petstore}/powerDown`,

  //StaticMangement
  staticContentList: `${ststic}/staticContentList`,
  staticContent: `${ststic}/staticContent`,
  faqList: `${ststic}/faqList`,
  addFAQ: `${ststic}/addFAQ`,
  editFAQ: `${ststic}/editFAQ`,
  viewFAQ: `${ststic}/viewFAQ/`,

  //Event Management
  group: `${event}/group/`,
  listEvent: `${event}/listEvent`,
  joinEvent: `${event}/joinEvent`,
  enterEvent: `${event}/enterEvent`,
  eventRacingDetails: `${event}/eventRacingDetails`,
  eventRaceCompleteResult: `${event}/eventRaceCompleteResult`,
  getDogHistory: `${event}/getDogHistory`,
  refundFundForEvents: `${event}/refundFundForEvents`,

  //Practice Management
  listPractice: `${practice}/listPractice`,
  practice: `${practice}/practice`,
  allPracticeModeDetails: `${practice}/allPracticeModeDetails`,

  //userCalls
  connectWallet: `${user}/connectWallet`,
  getProfile: `${user}/getProfile`,
  editProfile: `${user}/editProfile`,
  uploadFile: `${user}/uploadFile`,
  subscribeNewsletter: `${user}/subscribeNewsletter`,
  socialLinks: `${user}/socialLinks`,
  getEventDetails: `${user}/getEventDetails`,
  petstores: `${user}/petstores`,
  likeDislikePetStore: `${user}/likeDislikePetStore`,
  buyPetstore: `${user}/buyPetstore`,
  likeDislikeEvent: `${user}/likeDislikeEvent`,
  getUserListDetails: `${user}/getUserListDetails`,
  createEventNewGroup: `${user}/createEventNewGroup`,
  getEventGroupList: `${user}/getEventGroupList`,
  createSpecialRoomEvent: `${user}/createSpecialRoomEvent`,
  editSpeicalRoomEvent: `${user}/editSpeicalRoomEvent`,
  getTopParticpentDogs: `${user}/getTopParticpentDogs`,
  subscribeNewsletterList: `${user}/subscribeNewsletterList`,
  getDashboardData: `${user}/getDashboardData`,

  //nftCalls
  createNFT: `${nft}/createNFT`,
  ipfsUpload: `${nft}/ipfsUpload`,
  uploadNFT: `${nft}/uploadNFT`,
  getMetadata: `${nft}/getMetadata`,
  setPetName: `${nft}/setPetName`,

  // event
  eventSchedule: `${event}/eventSchedule`,
  event: `${event}/event`,
  eventView: `${event}/eventView`,

  // circuit
  listCircuit: `${circuit}/listCircuit`,

  // user event live or upcoming

  upcomingEvents: `${user}/upcomingEvents`,
  pastEvents: `${user}/pastEvents`,
  getUpcomingTournament: `${user}/getUpcomingTournament`,
  userAnnouncementTournament: `${user}/userAnnouncementTournament`,
  joinAnnouncementTournament: `${user}/joinAnnouncementTournament`,
  globallySearch: `${user}/globallySearch`,
  getForParticularTournamentData: `${user}/getForParticularTournamentData`,
  enterEventFee: `${user}/enterEventFee`,
  getEventFeeData: `${user}/getEventFeeData`,
  getUserDetails: `${user}/getUserDetails`,
  getAllEventDetails: `${user}/getAllEventDetails`,
  updateWinnerData: `${user}/updateWinnerData`,
  getAnnouncementData: `${user}/getAnnouncementData`,
  nextRoundGame: `${user}/nextRoundGame`,

  // Notication
  listNotification: `${base}/notification/listNotification`,
};

export default Apiconfigs;
