import { Contract } from "@ethersproject/contracts";
import { RPC_URL } from "src/constants";
import Web3 from "web3";
import moment from "moment";
import { useEthersSigner } from "./ethersSigner";
import { ethers } from "ethers";
import { chatApiMainUrl } from "src/apiConfig/config";

export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 4)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}

export function getContract(address, ABI, signer) {
  return new ethers.Contract(address, ABI, signer);
}

export const getWeb3Obj = () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = (abi, contractAddress) => {
  const web3 = getWeb3Obj(RPC_URL);
  const contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};
export const calculateTimeLeftForOtp = (endDate) => {
  if (endDate) {
    let difference = endDate * 1000 - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      return timeLeft;
    } else {
      return "live";
    }
  } else {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
};
export const calculateTimeLeftForRace = (endDate) => {
  if (endDate) {
    // let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (endDate > 0) {
      timeLeft = {
        days: Math.floor(endDate / (1000 * 60 * 60 * 24)),
        hours: Math.floor((endDate / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((endDate / 1000 / 60) % 60),
        seconds: Math.floor((endDate / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};
export const findEventStatus = (startDate, endDate) => {
  if (startDate && endDate) {
    if (moment(endDate).unix() < moment().unix()) {
      return "Ended";
    } else if (
      moment().unix() < moment(endDate).unix() &&
      moment().unix() > moment(startDate).unix()
    ) {
      return "Live";
    } else {
      return "Upcoming";
    }
  }
};
export const findTournamentStatus = (startDate) => {
  if (startDate) {
    if (moment(startDate).unix() < moment().unix()) {
      return "Ended";
    } else if (
      moment().unix() < moment(startDate).unix() &&
      moment().unix() > moment(startDate).unix()
    ) {
      return "Live";
    } else {
      return "Join Now";
    }
  }
};

export const paramsVal = chatApiMainUrl
  ? {
      secure: true,
      reconnect: true,
      rejectUnauthorized: false,
      // transports: ["websocket"],
      transports: ["websocket", "polling", "flashsocket"],
    }
  : {
      secure: true,
      reconnect: true,
      rejectUnauthorized: false,
      transports: ["websocket", "polling", "flashsocket"],
    };

export const formatNumberInteger = (num, lenVal) =>
  Number.isInteger(num)
    ? num.toLocaleString()
    : +parseFloat(num).toFixed(lenVal).toLocaleString();
